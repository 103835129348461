<template>
  <div
    class="masonry-item_type_image"
  >
    <template
      v-if="loaded"
    >
      <template
        v-if="(item.thumbnail.originalUrl || item.content.originalUrl)"
        style="border-radius: inherit; position: relative;"
      >
        <img
          width="100%"
          :height="isMultipart ? 200 : '100%'"
          :draggable="false"
          @error="e => loadImageErrorCb(e, itemId)"
          :data-origin="item.content.originalUrl"
          :src="item.thumbnail.originalUrl || item.content.originalUrl"
          :ref="`masonry-item-image-${itemId}`"
          :id="`masonry-item-image-${itemId}`"
          class="flex-grow-1"
          style="border-radius: inherit !important;"
          @click="onClick"
        />
        <v-progress-circular 
          indeterminate
          width="3"
          size="50"
          color="white"
          style="position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%);"
          v-if="inPrepareProcess"
        />
      </template>
      <template
        v-if="false"
      >
        <div
          class="d-flex justify-center align-center"
          style="min-height: 249px; background-color: #070707; color: #fff;"
        >
          Broken image!
        </div>
      </template>
      <div class="masonry-content d-flex justify-space-between" v-if="item.info && (item.info.title || item.info.description)">
        <div>
          <h4 class="masonry-item__title" v-if="item.info && item.info.title">{{ getExecuteStr(item.info.title)}}</h4>
          <p class="masonry-item__content mb-0" v-if="item.info && item.info.description">{{ getExecuteStr(item.info.description)}}</p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  props: {
    inPrepareProcess: {
      type: Boolean,
      default: () => false
    },
    isMultipart: {
      type: Boolean,
      default: () => false
    },
    loaded: {},
    height: {},
    itemId: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    brokenIds: {
      type: Array,
      required: true
    }
  },
  name: 'ItemTypeImage',
  data: () => ({
    loadedOriginal: true
  }),
  methods: {
    waitingFor(context, prop) {
      return new Promise(res => {
        const interval = setInterval(() => {
          const variable = context[prop]
          if (variable) {
            clearInterval(interval)
            res(variable)
          }
        }, 0)
      })
    },
    async onClick() {
      // this.loadedOriginal = false
      // this.waitingFor(window, '$previewer')
      //   .then(globalPreviewer => this.waitingFor(globalPreviewer, '$previewer'))
      //   .then(previewer => {
      //     this.loadedOriginal = !!previewer
      //   })
    },
    loadImageErrorCb(e, itemId) {
      this.$emit('addBrokenId', itemId)
    },
    getExecuteStr(str) {
      return window.tp.execute(str)
    }
  },
  computed: {
    thumbnailOriginal() {
      return this.item.thumbnail.original
    },
    original() {
      return this.item.content.original
    }
  }
}
</script>
<style lang="scss">

.masonry-item_type_image {
  img {
    cursor: pointer;
  }
}

img[lazy=loading] {
  opacity: 0;
  transition: opacity 0.3s;
}
img[lazy=error] {
  /*your style here*/
}
img[lazy=loaded] {
  opacity: 1;
    transition: opacity 0.3s;
}
</style>